import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Portfolios `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-xl/"
            }}>{`All-Stars XL`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-tr/"
            }}>{`All-Stars Total Return`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-weekly/"
            }}>{`All-Stars Weekly`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth, while maintaining tax-efficiency`}</li>
        <li parentName="ul">{`Type: meta portfolio`}</li>
        <li parentName="ul">{`Invests in: leveraged ETFs tracking stocks, bonds, commodities, VIX Futures`}</li>
        <li parentName="ul">{`Rebalancing: daily`}</li>
        <li parentName="ul">{`Taxation: 50% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $20,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`All-Stars Tax-Efficient`}</em>{` aims to continually outperform the balanced 60/40 benchmark while optimizing for after-tax returns. The meta-portfolio combines two strategies designed to reduce the tax load: a trend-following hedging strategy and a volatility-targeting strategy. This results in a tax load similar to strategic portfolios while also improving diversification. With its daily rebalancing schedule, `}<em parentName="p">{`All-Stars Tax-Efficient`}</em>{` has higher-than-average maintenance requirements. To summarize, the portfolio appeals to tactical investors focused on tax efficiency.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, All-Stars Tax-Efficient: A tax-efficient meta-strategy",
                "title": "TuringTrader.com, All-Stars Tax-Efficient: A tax-efficient meta-strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/all-stars-portfolios/"
            }}>{`All-Stars Portfolios`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/tax-efficient-investing/"
            }}>{`Tax-Efficient Investing`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`All-Stars Tax-Efficient`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`divide capital into two equal-sized tranches`}</li>
        <li parentName="ul">{`allocate one tranche each to TuringTrader's `}<a parentName="li" {...{
            "href": "/portfolios/tt-four-fifteen/"
          }}>{`Four-Fifteen`}</a>{` and (a more aggressive variant of) `}<a parentName="li" {...{
            "href": "/portfolios/tt-vix-spritz/"
          }}>{`VIX Spritz`}</a></li>
        <li parentName="ul">{`rebalance between tranches once per month`}</li>
      </ul>
      <p>{`By combining two strategies, `}<em parentName="p">{`All-Stars Tax-Efficient`}</em>{` diversifies across investment styles. As a result, the meta-strategy achieves higher risk-adjusted returns than its individual components.`}</p>
      <p>{`Both underlying strategies aim to hold onto their U.S. stock market exposure and avoid incurring short-term capital gains. While `}<em parentName="p">{`Four-Fifteen`}</em>{` uses an inverse-leveraged ETF to reduce stock market exposure, `}<em parentName="p">{`VIX Spritz`}</em>{` uses an ETF tracking VIX futures to achieve a similar effect. Interested investors may find more detail in the description of the respective strategies.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`In bullish periods, `}<em parentName="p">{`All-Stars Tax-Efficient`}</em>{` invests up to 100% of its capital in the U.S. Stock Market. This exposure is complemented with a minor position in U.S. Treasuries.`}</p>
      <p>{`In bearish markets, `}<em parentName="p">{`All-Stars Tax-Efficient`}</em>{` gradually reduces exposure to the S&P 500 to as low as zero while increasing the exposure to U.S. Treasuries and VIX futures.`}</p>
      <p>{`With this asset mix, `}<em parentName="p">{`All-Stars Tax-Efficient`}</em>{` is well-diversified with a low concentration risk. Further, through combining momentum and volatility-targeting mechanisms, the portfolio broadens the range of market scenarios it can cover successfully.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`Overall, `}<em parentName="p">{`All-Stars Tax-Efficient`}</em>{` delivers smooth returns at volatility comparable to a passive 60/40. The Monte-Carlo simulation supports this claim in showing an almost identical risk profile.`}</p>
      <p><em parentName="p">{`All-Stars Tax-Efficient`}</em>{` handily beats the 60/40 benchmark in most years. Further, when contemplating the complete economic cycle, the strategy beats the S&P 500 by a wide margin. The rolling returns show how the portfolio is continually gaining over its benchmark with only brief periods of underperformance.`}</p>
      <p>{`The portfolio uses its holdings of inverse-leveraged ETFs and products tracking VIX futures only to hedge existing exposure to the S&P 500. Further, the total portfolio exposure, including the potential holdings of leveraged U.S. Treasury ETFs, does not exceed 100%. With these properties, the strategy has a lower tail risk than holding the S&P 500.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`The hedging techniques used with `}<em parentName="p">{`All-Stars Tax-Efficient`}</em>{` allow holding onto at least 30% of the S&P 500 shares at all times. In typical scenarios, the percentage of S&P 500 shares held for more than 12 months is significantly higher than that.`}</p>
      <p><em parentName="p">{`All-Stars Tax-Efficient`}</em>{` trades frequently and triggers taxable events on the way. However, these taxable events typically only affect a fraction of the total account, leading to a tax burden similar to a passive 60/40.`}</p>
      <p>{`The portfolio makes use of complex and leveraged ETFs. Most brokerages require signing additional disclosures before allowing investors to use these instruments in their accounts. Because we use these ETFs only for hedging purposes, we believe our use of these products to be responsible.`}</p>
      <p>{`Because of the complex ETF's inefficiencies, most importantly borrowing costs, and volatility decay, `}<em parentName="p">{`All-Stars Tax-Efficient`}</em>{` should only be used in situations where tax-efficiency is a primary criterion. Further, the account size should exceed $20,000 to achieve accurate position sizing.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, February 2021`}</a>{`: Initial release, combining `}<a parentName="li" {...{
            "href": "/portfolios/tt-four-fifteen/"
          }}>{`Four-Fifteen`}</a>{`, and `}<a parentName="li" {...{
            "href": "/portfolios/tt-vix-spritz/"
          }}>{`VIX Spritz`}</a>{`.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, April 2022`}</a>{`: Upgraded `}<a parentName="li" {...{
            "href": "/portfolios/tt-vix-spritz/"
          }}>{`VIX Spritz`}</a>{` to its latest revision for improved behavior with rising rates.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=3"
          }}>{`v3, October 2022`}</a>{`: Upgraded `}<a parentName="li" {...{
            "href": "/portfolios/tt-four-fifteen/"
          }}>{`Four Fifteen`}</a>{` to its latest revision to better cope with rising rates.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      